<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">แก้ไขลอตเตอรี่</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" sm="3"
            ><span>เลขลอตเตอรี่</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="lotteryNo"
              placeholder="เลขลอตเตอรี่"
              solo
              dense
              type="number"
              v-mask="'######'"
              :rules="rules.max6"
              counter="6"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>งวดที่</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="time"
              placeholder="งวดที่"
              type="number"
              solo
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>ชุดที่</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="set"
              placeholder="ชุดที่"
              type="number"
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span>จำนวนสต็อคสินค้า</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="stock"
              placeholder="จำนวนสต็อคสินค้า"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>ราคาขาย</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="price"
              placeholder="ราคาขาย"
              solo
              dense
              type="number"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>เจ้าของ</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="owner"
              placeholder="เจ้าของ"
              solo
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>เลขที่สต้อค</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="stockNo"
              placeholder="เลขที่สต้อค"
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">วันที่ออก</span>
            <span style="color: red">*</span></v-col
          >

          <v-col cols="12" md="9" sm="9">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-menu
                  v-model="menufrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="lotteryDate"
                      label="วันที่ออก"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                      clearable
                      @click:clear="lotteryDate = null"
                      :rules="rules.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="lotteryDate"
                    @input="menufrom = false"
                    @click="$refs.menufrom.save(lotteryDate)"
                  ></v-date-picker>
                  <!-- <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="lotteryDate"
                        label="วันที่ออก"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="lotteryDate"
                      no-title
                      scrollable
                      :min="firstDate"
                    >
                      <v-btn text color="primary" @click="menufrom = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufrom.save(lotteryDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker> -->
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="12" sm="6" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center" justify="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปลอตเตอรี่</span>
                  <span style="color: red">*</span>
                  <v-row align="center" justify="center">
                    <v-img
                      v-model="picture_1"
                      @click="changePic1()"
                      src="@/assets/upload.png"
                      class="mr-3"
                      max-width="50"
                    >
                    </v-img>
                    <span>เลือกรูปภาพ</span>
                  </v-row>
                  <v-col
                    cols="3"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage(index, item)"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                /></v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-row class="ma-5" align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <v-btn @click="submit()" color="primary">บันทึก</v-btn>
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import draggable from "vuedraggable";
import { Decode } from "@/services";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      menufrom: false,
      lotteryDate: "",
      lotteryNo: "",
      time: "",
      set: "",
      stock: "",
      owner: "",
      stockNo: "",
      price: 80,

      urlImage: "",
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        max6: [
          (v) => v.length == 6 || "หมายเลขลอตเตอรี่ต้องมี 6 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
      },
      checkValidate: true,
      lazy: false,
      //
    };
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          var resultReader = reader.result;
          var url = URL.createObjectURL(element);
          this.urlImage.push({
            image_data: resultReader,
            url: url,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.urlImage.splice(index, 1);
    },
    cancel() {
      this.$router.push("ManageLotto");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const productData = {
          lotteryDate: this.lotteryDate,
          lotteryNo: this.lotteryNo,
          time: this.time,
          set: this.set,
          stock: this.stock,
          owner: this.owner,
          price: this.price,
          stockNo: this.stockNo,

          urlImage: this.pic1,
          //   pic1: "",
          //   picture_1: "",
          //   showImage: "",
          //   imageName1: "",
        };
        console.log("data", productData);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/lottery/` + this.id,
          productData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขลอตเตอรี่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageLotto");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขลอตเตอรี่ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async getOneLotto() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/lottery/getLottery/` + this.id,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getOneLotto", response.data);
      const Lottodata = response.data.data;
      // this.id = orderdata.id;
      this.lotteryDate = Lottodata.lotteryDate;
      this.lotteryNo = Lottodata.lotteryNo.toString();
      this.time = Lottodata.time;
      this.set = Lottodata.set;
      this.stock = Lottodata.stock;
      this.owner = Lottodata.owner;
      this.stockNo = Lottodata.stockNo;
      this.price = Lottodata.price;
      this.showImage = Lottodata.urlImage;
    },
  },
  async created() {
    var Lottodata = JSON.parse(
      Decode.decode(localStorage.getItem("Lottodata"))
    );
    console.log("Lottodata", Lottodata);
    this.id = Lottodata.id;
    this.getOneLotto();
    // urlImage: this.pic1;
  },
};
</script>
